import React from "react";
import Ample from "../components/ample/ample";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const PCRatiosPage = (props) => {  
  //OutboundLink
  const explainerBlurb =<div>
  Here we chart the ratio of open option interest in puts vs. calls (a measure of the amount of caution in the market).  It is sometimes the case that when this ratio's trendline (or rolling average) 'rolls over' and starts a new trend, the overall stock market soon follows.  Overlaid in the backgroud is a plot of the daily closing price of SPY, a proxy for the <OutboundLink href="https://www.investopedia.com/terms/s/sp500.asp">S&P 500 index</OutboundLink>
  </div>;

  const seoDescription="Chart of put/call ratio, the open option interest in puts vs. calls, a measure of the amount of caution in the market, overlaid over the S&P 500, along with buy and sell signals from the trendline";

  const chart_url=`${process.env.GATSBY_CONTENT_SITE_URL}/plots/pc_ratio_spx.html`;

  return (
    <Ample currPageTitle="Equity-only Put/Call Ratios" explainerBlurb={explainerBlurb} seoDescription={seoDescription}  location={props.location.href}>
      <div className="row">
        <div className="col-md-12">
          <div className="white-box">
            <div className="box-title">Put/Call Ratios</div>
              <iframe 
                src={chart_url}
                border="0" width="800" height="600" 
                style= {{border:`none`}}
                title="Put/Call Ratios">
              </iframe>
            </div>
          </div>
        </div>
    </Ample>
  );
};

export default PCRatiosPage;

